export const portalRouter = "";
export const dataMangeRouter = "manage";
export const systemMangeRouter = "manage/settings";
import APP_MYAPP_ROUTER from "@/app_myapp/router.js";
import APP_AUTH_ROUTER from "@/app_auth/router.js";
import APP_MANAGE_USER_ROUTER from "@/app_user/router.js";
import APP_MANAGE_SYSTEM_ROUTER from "@/app_system_manage/router.js";
import APP_MANAGE_SUBJECT_ROUTER from "@/app_manage_subject/router.js"
import APP_MANAGE_PLACE_ROUTER from "@/app_manage_place/router.js"
import APP_MANAGE_OBJECT3D_ROUTER from "@/app_manage_object3d/router.js"
import APP_MANAGE_VR360_ROUTER from "@/app_manage_vr360/router.js"
import APP_MANAGE_FESTIVAL_ROUTER from "@/app_manage_festival/router.js"
import APP_MAPGL_ROUTER from "@/app_mapgl/router.js";

export default [
    ...APP_MYAPP_ROUTER('system'),
    ...APP_MANAGE_USER_ROUTER('manage/user'),
    ...APP_AUTH_ROUTER(''),
    ...APP_MANAGE_SYSTEM_ROUTER('system/settings'),
    ...APP_MANAGE_SUBJECT_ROUTER(''),
    ...APP_MANAGE_PLACE_ROUTER(''),
    ...APP_MANAGE_OBJECT3D_ROUTER(''),
    ...APP_MANAGE_VR360_ROUTER(''),
    ...APP_MANAGE_FESTIVAL_ROUTER(''),
    ...APP_MAPGL_ROUTER(''),
];
