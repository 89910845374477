import userState from "../../state/dataUser";
import { checkLogout, checkManage } from "./page_case.js";

function getFullCasePath(paths) {
  let extendPaths = []
  paths.forEach(originPath => {
    const extendPs = [originPath, `/${originPath}`, `/${originPath}/`]
    extendPaths = [...extendPaths, ...extendPs]
  })
  return extendPaths
}

const RouterCheck = async (to, from, next) => {
  const isUserLogined = userState.value?.id
  const { name, matched } = to;
  const requiresAuth = true
  const systemPath = getFullCasePath(['system'])
  const authPath = getFullCasePath(['login', 'register', 'forgot'])
  const errorsPath = getFullCasePath(['error-403', 'error-404'])

  const autoPassPath = [...systemPath, ...authPath, ...errorsPath]
  const logoutPath = getFullCasePath(['logout'])
  if (logoutPath.includes(to.fullPath)) {
    checkLogout(requiresAuth, next);
  }
  else if (to.fullPath == "/") {
    let path = matched[0]?.path
    if (!path) next('/system')
    else await checkManage(matched, next, isUserLogined);
  }
  else if (autoPassPath.includes(to.fullPath))
    next()
  else {
    await checkManage(matched, next, isUserLogined);
  }

  // Check có phải là các page cá nhân người dùng hoặc page mặc định k yêu cầu 
  // Có đăng nhập

  // Không đăng nhập
  // Check trạng thái ứng dụng
  // requireLogin 
  // => Redirect to login
  // notRequireLogin
  // => next()

  if (requiresAuth && !userState.value?.id) {
    // next({ name: "Login", query: { redirect: name, ...params } });
    next('/login')
  } else if (userState.value?.id) {
    const authRoutes = ["Login", "Register", "ResetPassword"];
    if (authRoutes.includes(name)) {
      next('/');
    } else if (name === "Home") {
      next();
    } else if (name === "Logout") {
      checkLogout(requiresAuth, next);
    } else if (matched.some((record) => record.meta.cpuiaPermission)) {
      await checkManage(matched, next);
    } else {
      next();
    }
  } else {
    next();
  }
};

export { RouterCheck };
