import axios from "axios";
import axiosHttp from "./axiosHttp.js";

const API = () => {
  const call = async (url) => {
    try {
      const reponsive = await axios.get(`${url}`);
      const data = await reponsive.data;
      return data;
    } catch (err) {
      return err;
    }
  };

  const get = async (url) => {
    try {
      const reponsive = await axiosHttp.get(`${url}`);
      const data = await reponsive.data;
      return data;
    } catch (err) {
      return err;
    }
  };

  const remove = async (url) => {
    try {
      const reponsive = await axiosHttp.delete(`${url}`);
      const data = await reponsive.data;
      return data;
    } catch (err) {
      return err;
    }
  };

  const post = async (url, dataPost) => {
    try {
      const reponsive = await axiosHttp.post(`${url}`, dataPost);
      const data = await reponsive.data;
      return data;
    } catch (err) {
      if (!err?.response?.status) return err;
      switch (err.response.status) {
        case 400:
          return {
            isBadReques: true,
            ...err.response.data,
          };
        case "400":
          return {
            isBadReques: true,
            ...err.response.data,
          };
        default:
          return err;
      }
    }
  };
  const patch = async (url, dataPost) => {
    try {
      const reponsive = await axiosHttp.patch(`${url}`, dataPost);
      const data = await reponsive.data;
      return data;
    } catch (err) {
      return err;
    }
  };
  return { get, remove, post, call, patch };
};

export default API;
