import { BASE_URL } from "../api/axiosHttp.js";
import http from "../api/axiosHttp.js";
import userState from "../../state/dataUser.js";
const Token = () => {
  async function setUser(user) {
    localStorage.setItem("auth", JSON.stringify(user));
    return await getUser();
  }

  function removeUser() {
    http.post(`/logout/`);
    localStorage.removeItem("auth");
    userState.value = {};
  }

  const getUser = async function (navigate = "") {
    const tokenString = localStorage.getItem("auth");
    const user = JSON.parse(tokenString);
    http.defaults.headers.common["Authorization"] = `Bearer ${user}`;
    http.defaults.headers.Authorization = `Bearer ${user}`;
    if (user) {
      let newUser;
      try {
        const res = await fetch(BASE_URL + "/api/user/current-user/", {
          method: "GET",
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${user}`,
          },
        });
        if (res.status === 200) {
          await res.json().then((res) => {
            newUser = {
              ...res,
              token: user,
              isLogin: true,
            };
          });
        } else {
          if (navigate) {
            // location.replace(`/account?next=${navigate}`);
            return;
          }
        }
      } catch (error) {
        return
      }
      userState.value = { ...newUser };
      return newUser;
    }
  };

  /*const getPermissionRole = async () => {
        const getCurrentUser = getUser();
    }*/

  return {
    getUser,
    setUser,
    removeUser,
    /*getPermissionRole*/
  };
};

export default Token;
