export default (url_root) => {
  const defName = "MapGL"
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: `${defName}.index`,
      component: () => import("./pages/MapGL.vue"),
      meta: {
        title: "Bản đồ du lịch Đại Từ",
      },
    },  
    {
      path: "/ban-do",
      name: `${defName}.index2`,
      component: () => import("./pages/MapGL.vue"),
      meta: {
        title: "Bản đồ du lịch Đại Từ",
      },
    },  
    {
      path: "/bando",
      name: `${defName}.index3`,
      component: () => import("./pages/MapGL.vue"),
      meta: {
        title: "Bản đồ du lịch Đại Từ",
      },
    },    
    {
      path: "/tour/:slug",
      name: `Tour.index`,
      component: () => import("./pages/tour/Tour.vue"),
      meta: {
        title: "Bản đồ tour du lịch",
      },
    },
    // Add more routes here if needed
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
