<template>
  <router-view></router-view>
</template>
<style>
.dropdown-item {
  cursor: pointer;
}
</style>

<script>
import { provide } from "vue";
import Token from "./helpers/user/user.js";
import userState from "./state/dataUser";

export default {
  name: "App",
  components: {},
  setup: function () {
    provide("user", userState);
    provide("logout", () => {
      Token().removeUser();
      userState.value = { isLogin: false };
    });
  },
};
</script>
